import React from 'react';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';

const Carousel = () => {
    // const sliderRef = useRef(null);
    // const [mounted, setMounted] = useState(false);
    //
    // useEffect(() => {
    //     setMounted(true);
    // }, []);
    //
    // if (!mounted) return null;

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 9000,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        height: '100vh',
    };


    return (
        <Slider {...settings}>
            <div >
                <div className="itemStyle page2"><h1>Complex solutions made simple with our secure, private and
                    innovative AI enhanced communication products</h1></div>
            </div>
            <div ><div className="itemStyle"></div></div>
            <div >
                <div className="itemStyle page2"><h1>Trusted solutions over 18 years</h1></div>
            </div>
            {/*<div style={slideStyle}>Slide Three</div>*/}
        </Slider>
    );
}

export const CarouselRegistration = () => {
    // const sliderRef = useRef(null);
    // const [mounted, setMounted] = useState(false);
    //
    // useEffect(() => {
    //     setMounted(true);
    // }, []);
    //
    // if (!mounted) return null;

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 9000,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        height: '100vh',
    };


    return (
        <Slider {...settings}>
            <div>
                <div className="registrationPage2 page1"></div>
                <div className="page-h1">Our solution ensures secure communications by protecting data integrity and privacy, applies logical analytics to distill complex messaging data into clear insights, and delivers relevant, actionable recommendations that drive effective customer engagement.
                </div>
            </div>
            <div>
                <div className="registrationPage2 page3"></div>
                <div className="page-h1">Upgrade your customer chat with AI-driven insights for real-time, personalized
                    support that boosts engagement and satisfaction.
                </div>
            </div>
            <div>
                <div className="registrationPage2"></div>
                <div className="page-h1">Empower your communications with our AI-driven reports - unlocking real-time
                    insights to boost customer engagement and streamline performance.
                </div>
            </div>
            {/*<div>*/}
            {/*    <div className="itemStyle page2"><h1>Trusted solutions over 18 years</h1></div>*/}
            {/*</div>*/}
            {/*<div style={slideStyle}>Slide Three</div>*/}
        </Slider>
    );
}


export default Carousel;
