import React, { useState } from 'react';
// Include CSS file for styling if needed
import './ContactUsPage.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here, implement your logic to send form data to your server or email service
        console.log(formData); // Example action
        alert('Thank you for reaching out to us!');
        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            message: '',
        });
    };

    return (
        <div className="contactUsContainer">
            <h2>Contact Us</h2>
            <p>If you have any questions, please don't hesitate to reach out. Fill out the form below or contact us
                through the following channels:</p>
            <div className="contactMethods">
                <form className="contactForm" onSubmit={handleSubmit}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required/>

                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}
                           required/>

                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" value={formData.message} onChange={handleChange}
                              required></textarea>

                    <button type="submit">Send Message</button>
                </form>
                <p><strong>Email:</strong> contact@voiceproconnect&#46;com</p>
                <p><strong>Phone:</strong> 1-855-444-0060</p>
                <p><strong>Address:</strong> 90 E Halsey Rd, Parsippany, NJ 07054</p>
                {/*<p>Follow us on social media:</p>*/}
                {/*<div className="socialLinks">*/}
                {/*    <a href="http://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a> |*/}
                {/*    <a href="http://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a> |*/}
                {/*    <a href="http://www.linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>*/}
                {/*</div>*/}
                <div className="googleMap">
                    <iframe name={"googleMap"} title="Google Map" className="googleMap"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.990002354237!2d-74.4211448!3d40.8501433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a7f13cceed9b%3A0x2cb202e6d6e0880a!2s90%20E%20Halsey%20Rd%2C%20Parsippany%2C%20NJ%2007054!5e0!3m2!1sen!2sus!4v1710103669386!5m2!1sen!2sus"
                        width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
