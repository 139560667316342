import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Carousel from "./Carousel";
import './Demo.css';

const DemoSignup = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const validateEmail = (val) => /\S+@\S+\.\S+/.test(val);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setError('Invalid email');
            return;
        }
        setError('');
        try {
            const response = await fetch('/api/demo-signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            if (!response.ok) throw new Error('Request failed');
            setSubmitted(true);
        } catch (err) {
            setError('Error submitting email. Please try again.');
        }
    };

    return (
        <div className="demo-container">
            <div className="left-side">
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                    }}
                >
                    <h2>Locate your VPC demo dashboard</h2>
                    {!submitted ? (
                        <>
                            <p>Enter your email, and we’ll send you a link.</p>
                            <form onSubmit={handleSubmit}>
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button type="submit">Retrieve your link</button>
                            </form>
                        </>
                    ) : (
                        <div>
                            <h3>Thank you for signing up!</h3>
                            <p style={{marginLeft:-8}}>Please check your email for further instructions.</p>
                        </div>
                    )}
                </div>
                <div className="footer-demo">
                    <div style={{ paddingTop: 10 }}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="120.125"
                             height="30.965" viewBox="0 0 208.125 56.965">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="208.125" height="56.965"
                                          fill="none"
                                          stroke="#ff9200" stroke-width="1"/>
                                </clipPath>
                                <clipPath id="clip-path-2">
                                    <rect id="Rectangle_2" data-name="Rectangle 2" width="208.125" height="56.965"
                                          fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Group_13" data-name="Group 13" transform="translate(-365.811 -22)">
                                <g id="Group_2" data-name="Group 2" transform="translate(365.811 22)">
                                    <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                                        <text id="V" transform="translate(0 31.465)" fill="#f7981d" stroke="#ff9200"
                                              stroke-width="1" font-size="36" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">V</tspan>
                                        </text>
                                        <text id="oice" transform="translate(22.047 31.465)" fill="#f7981d"
                                              stroke="#ff9200"
                                              stroke-width="1" font-size="37" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">oice</tspan>
                                        </text>
                                        <text id="PRO" transform="translate(96.443 31.465)" fill="#b2b2b2"
                                              font-size="37"
                                              font-family="Helvetica-BoldOblique, Helvetica" font-weight="700"
                                              font-style="oblique">
                                            <tspan x="0" y="0">PRO</tspan>
                                        </text>
                                        <text id="T" transform="translate(181.715 8.75)" fill="#989897" font-size="7"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">T</tspan>
                                        </text>
                                        <text id="M" transform="translate(187.015 9.75)" fill="#989897" font-size="8"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">M</tspan>
                                        </text>
                                        <path id="Path_1" data-name="Path 1"
                                              d="M24.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(4.024 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(20.838 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                        <path id="Path_2" data-name="Path 2"
                                              d="M15.25,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(1.87 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(9.685 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                                <text id="communications" transform="translate(411.026 67.416)" fill="#e0e0e0"
                                      font-size="13"
                                      font-family="Helvetica" letter-spacing="0.229em">
                                    <tspan x="0" y="0">communications</tspan>
                                </text>
                                <g id="Group_4" data-name="Group 4" transform="translate(365.811 22)">
                                    <g id="Group_3" data-name="Group 3" clip-path="url(#clip-path-2)">
                                        <path id="Path_3" data-name="Path 3"
                                              d="M33.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(6.178 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(31.992 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div>VoicePro Communications, Inc. @ 2025</div>
                    <div>
                        <a href="/terms">Terms of Use</a> | <a href="/privacy">Privacy</a>
                    </div>
                </div>
            </div>
            <div className="carousel-container">
                <div>
                    <Carousel/>
                </div>
            </div>
        </div>
    );
};

export default DemoSignup;
