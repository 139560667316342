import React from "react";
import './main.css';

export default function Main(){

    return(
        <div className="section-container">
            <section className="section-one">
                <div className="section-group">
                    <div className="floating-text">
                        <h2>The way to connect your business</h2>
                        <p>Connecting your business has never been easier than with our solutions.
                            Our streamlined approach to connectivity allows for a seamless integration
                            of all your communication needs. </p>
                        <div className="buttons">
                            <a href="https://www.voiceproconnect.com/demo" className="login-button getstarted-button">Get
                                Started with Voice PRO Solutions</a>

                        </div>
                    </div>
                    <div className="floating-image"></div>
                </div>


            </section>
            <section className="section-two">
                <div className="section-group grop2">
                    <div className="floating-text text2">
                        <h2>Complex solutions made simple</h2>
                        <p>With our VoIP solutions, managing complex communication problems is made simple.
                            Say goodbye to confusion and hello to efficient and effective communication with our VoIP services.
                            Let us help you connect the dots and take your business to the next level. </p>
                        <div className="buttons">
                            <a href="https://www.voiceproconnect.com/demo" className="login-button getstarted-button">Explore
                                Options</a>

                        </div>
                    </div>
                    <div className="floating-image image2">
                    </div>
                </div>
            </section>

            <section className="section-three">
                <div className="section-group2">
                    <div className="floating-text text3">
                        <h2>Custom API Integrations</h2>
                        <p>Looking to streamline your business operations and take them to the next level? Our custom API
                            integrations provide a solution that can simplify and optimize your workflows, making your business
                            more efficient and effective.
                            We understand that every business has unique needs, which is why we work with you to create tailored
                            integrations that perfectly match your requirements. Experience the power of seamless integration
                            with our custom API solutions today. </p>
                    </div>
                    <div className="section-footer-logos">
                        <div className="ailogify-logo">
                            <img src="./assets/ailogify_logo@3x.png" height="36.78" width="123.62" alt="ailogify"/>
                        </div>
                        <div className="vinsolutions-logo">
                           VinSolutions
                        </div>
                        <div className="pci-logo">
                            <img src="./assets/pci@3x.png" height="44" width="83" alt="ailogify"/>
                        </div>
                        <div className="sas-logo">
                            <img src="./assets/sas70@3x.png" height="40" width="106" alt="ailogify"/>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}
