// RegistrationWizard.jsx
import React, { useState, useEffect } from 'react';
import Carousel, {CarouselRegistration} from "./Carousel";
import './Registration.css';


export default function RegistrationWizard() {
    // Step 1: Activation, Step 2: Registration details,
    // Step 3: Additional questions, Step 4: CRM question (if dealership),
    // Step 5: Set password, Step 6: Final thank-you.
    const [step, setStep] = useState(1);
    // set activation code from url if available code variable
    const [activationCode, setActivationCode] = useState('');
    const [userData, setUserData] = useState({
        name: '',
        businessName: '',
        industry: '',
        phone: '',
    });
    const [industryQuestions, setIndustryQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    // For step 4 (CRM) and step 5 (password)
    const [crm, setCrm] = useState('');
    const [credentials, setCredentials] = useState({
        password: '',
        confirmPassword: '',
    });
    const [error, setError] = useState('');
    const [finalMessage, setFinalMessage] = useState('');

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    useEffect(() => {
        if (code) {
            setActivationCode(code);
        }
    } , [code]);
    // Mobile detection for hiding right side carousel.
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Step 1: Activation Code Verification
    const handleActivationSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const res = await fetch('/api/activate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ activationCode }),
            });
            if (!res.ok) throw new Error('Invalid activation code');
            setStep(2);
        } catch (err) {
            setError(err.message);
        }
    };

    // Step 2: Registration Details
    const handleRegistrationSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const payload = { activationCode, ...userData };
            const res = await fetch('/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            const data = await res.json();
            if (!res.ok) throw new Error(data.error || 'Registration failed');
            // If API returns additional industry-specific questions, proceed to step 3.
            if (data.requireQuestions) {
                setIndustryQuestions(data.questions);
                setStep(3);
            } else if (userData.industry === 'dealership') {
                // If dealership, go to separate CRM question step.
                setStep(4);
            } else {
                // Otherwise, move directly to set password.
                setStep(5);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    // Step 3: Additional Industry-Specific Questions
    const handleAdditionalQuestionsNext = (e) => {
        e.preventDefault();
        // After answering questions, if industry is dealership, go to CRM step.
        if (userData.industry === 'dealership') {
            setStep(5);
        } else {
            setStep(5);
        }
    };

    // Step 4: Ask "Which CRM do you use?" for dealerships
    const handleCrmSubmit = (e) => {
        e.preventDefault();
        if (!crm.trim()) {
            setError('Please enter your CRM');
            return;
        }
        setError('');
        setStep(5);
    };

    // Step 5: Set Password with Confirmation
    const handleCredentialsSubmit = async (e) => {
        e.preventDefault();
        setError('');
        if (credentials.password !== credentials.confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        if (credentials.password.length < 8) {
            setError('Password must be at least 8 characters long');
            return;
        }
        if (!/[A-Z]/.test(credentials.password)) {
            setError('Password must contain at least one uppercase letter');
            return;
        }
        if (!/[a-z]/.test(credentials.password)) {
            setError('Password must contain at least one lowercase letter');
            return;
        }
        if (!/[0-9]/.test(credentials.password)) {
            setError('Password must contain at least one number');
            return;
        }
        if (!/[!@#$%^&*]/.test(credentials.password)) {
            setError('Password must contain at least one special character');
            return;
        }
        setError('');
        // Proceed to final registration step.

        try {
            const payload = {
                activationCode,
                ...userData,
                answers,
                // Include CRM if dealership; otherwise, null.
                crm: userData.industry === 'dealership' ? crm : null,
                password: credentials.password,
            };
            const res = await fetch('/api/register/complete', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
            if (!res.ok) throw new Error('Final registration failed');
            setFinalMessage('Registration complete! Thank you for signing up. You can now ');
            setStep(6);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="demo-container">
            {/* Left Side: Registration Wizard */}
            <div className="left-side">
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                }}>
                    {step === 1 && (
                        <form onSubmit={handleActivationSubmit}>
                            <h2>Activate Your Account</h2>
                            <p>Enter the activation code from your email</p>
                            {error && <p className="error">{error}</p>}
                            <input
                                className="input-field"
                                type="text"
                                placeholder="Activation Code"
                                value={activationCode}
                                onChange={e => setActivationCode(e.target.value)}
                                required
                            />
                            <button type="submit">Activate</button>
                        </form>
                    )}
                    {step === 2 && (
                        <form onSubmit={handleRegistrationSubmit}>
                            <h2>Complete Registration</h2>
                            {error && <p className="error">{error}</p>}
                            <input
                                className="input-field"
                                type="text"
                                placeholder="Name"
                                value={userData.name}
                                onChange={e => setUserData({ ...userData, name: e.target.value })}
                                required
                            />
                            <input
                                className="input-field"
                                type="text"
                                placeholder="Business Name"
                                value={userData.businessName}
                                onChange={e => setUserData({ ...userData, businessName: e.target.value })}
                                required
                            />
                            <input
                                className="input-field"
                                type="tel"
                                placeholder="Contact Phone"
                                value={userData.phone}
                                onChange={e => setUserData({ ...userData, phone: e.target.value })}
                                required
                            />
                            <select
                                className="input-field"
                                value={userData.industry}
                                onChange={e => setUserData({ ...userData, industry: e.target.value })}
                                required
                            >
                                <option value="">Select Industry</option>
                                <option value="dealership">Auto Dealership</option>
                                <option value="healthcare">Healthcare</option>
                                <option value="education">Education</option>
                                <option value="finance">Finance</option>
                                <option value="retail">Retail</option>
                                <option value="realestate">Real Estate</option>
                                <option value="tech">Technology</option>
                                <option value="other">Other</option>
                            </select>
                            <button type="submit">Next</button>
                        </form>
                    )}
                    {step === 3 && (
                        <form onSubmit={handleAdditionalQuestionsNext}>
                            <h2>Additional Questions</h2>
                            {error && <p className="error">{error}</p>}
                            {industryQuestions.map(q => (
                                <div key={q.id}>
                                    <p>{q.question_text}</p>
                                    {q.question_type === 'text' && (
                                        <input
                                            className="input-field"
                                            type="text"
                                            onChange={e => setAnswers(prev => ({ ...prev, [q.id]: e.target.value }))}
                                            required
                                        />
                                    )}
                                    {q.question_type === 'multiple-choice' && (
                                        <select
                                            className="input-field"
                                            onChange={e => setAnswers(prev => ({ ...prev, [q.id]: e.target.value }))}
                                            required
                                        >
                                            <option value="">Select an option</option>
                                            {q.options.map((option, idx) => (
                                                <option key={idx} value={option}>{option}</option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            ))}
                            <button type="submit">Next</button>
                        </form>
                    )}
                    {/*{step === 4 && (*/}
                    {/*    <form onSubmit={handleCrmSubmit}>*/}
                    {/*        <h2>Which CRM do you use?</h2>*/}
                    {/*        {error && <p className="error">{error}</p>}*/}
                    {/*        <input*/}
                    {/*            className="input-field"*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Enter CRM Name"*/}
                    {/*            value={crm}*/}
                    {/*            onChange={e => setCrm(e.target.value)}*/}
                    {/*            required*/}
                    {/*        />*/}
                    {/*        <button type="submit">Next</button>*/}
                    {/*    </form>*/}
                    {/*)}*/}
                    {step === 5 && (
                        <form onSubmit={handleCredentialsSubmit}>
                            <h2>Set Your Password</h2>
                            {error && <p className="error">{error}</p>}
                            <input
                                className="input-field"
                                type="password"
                                placeholder="Password"
                                value={credentials.password}
                                onChange={e => setCredentials({ ...credentials, password: e.target.value })}
                                required
                            />
                            <input
                                className="input-field"
                                type="password"
                                placeholder="Confirm Password"
                                value={credentials.confirmPassword}
                                onChange={e => setCredentials({ ...credentials, confirmPassword: e.target.value })}
                                required
                            />
                            <p>Password must be at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character.</p>
                            <button type="submit">Complete Registration</button>
                        </form>
                    )}
                    {step === 6 && (
                        <div>
                            <h2>Thank You!</h2>
                            <p>
                                {finalMessage} <a href="/demologin">Login here</a>.
                            </p>
                        </div>
                    )}
                </div>
                <div className="footer-demo">
                    <div style={{paddingTop: 10}}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="120.125"
                             height="30.965" viewBox="0 0 208.125 56.965">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="208.125" height="56.965"
                                          fill="none"
                                          stroke="#ff9200" stroke-width="1"/>
                                </clipPath>
                                <clipPath id="clip-path-2">
                                    <rect id="Rectangle_2" data-name="Rectangle 2" width="208.125" height="56.965"
                                          fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Group_13" data-name="Group 13" transform="translate(-365.811 -22)">
                                <g id="Group_2" data-name="Group 2" transform="translate(365.811 22)">
                                    <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                                        <text id="V" transform="translate(0 31.465)" fill="#f7981d" stroke="#ff9200"
                                              stroke-width="1" font-size="36" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">V</tspan>
                                        </text>
                                        <text id="oice" transform="translate(22.047 31.465)" fill="#f7981d"
                                              stroke="#ff9200"
                                              stroke-width="1" font-size="37" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">oice</tspan>
                                        </text>
                                        <text id="PRO" transform="translate(96.443 31.465)" fill="#b2b2b2"
                                              font-size="37"
                                              font-family="Helvetica-BoldOblique, Helvetica" font-weight="700"
                                              font-style="oblique">
                                            <tspan x="0" y="0">PRO</tspan>
                                        </text>
                                        <text id="T" transform="translate(181.715 8.75)" fill="#989897" font-size="7"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">T</tspan>
                                        </text>
                                        <text id="M" transform="translate(187.015 9.75)" fill="#989897" font-size="8"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">M</tspan>
                                        </text>
                                        <path id="Path_1" data-name="Path 1"
                                              d="M24.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(4.024 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(20.838 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                        <path id="Path_2" data-name="Path 2"
                                              d="M15.25,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(1.87 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(9.685 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                                <text id="communications" transform="translate(411.026 67.416)" fill="#e0e0e0"
                                      font-size="13"
                                      font-family="Helvetica" letter-spacing="0.229em">
                                    <tspan x="0" y="0">communications</tspan>
                                </text>
                                <g id="Group_4" data-name="Group 4" transform="translate(365.811 22)">
                                    <g id="Group_3" data-name="Group 3" clip-path="url(#clip-path-2)">
                                        <path id="Path_3" data-name="Path 3"
                                              d="M33.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(6.178 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(31.992 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div>VoicePro Communications, Inc. @ 2025</div>
                    <div>
                        <a href="/terms">Terms of Use</a> | <a href="/privacy">Privacy</a>
                    </div>
                </div>
            </div>
            {/* Right Side: Carousel (only show on non-mobile screens) */}
            {!isMobile && (
                <div className="carousel-container-registration">
                    <div>
                        <CarouselRegistration/>
                    </div>
                </div>
            )}
        </div>
    );
}
