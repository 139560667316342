// ForgotPasswordPage.jsx
import React, { useState, useEffect } from 'react';
import Carousel from "./Carousel";
import './login.css';

export default function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');
        setLoading(true);
        try {
            const res = await fetch('https://api.voiceproconnect.com/forgot-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            const data = await res.json();
            if (!res.ok) {
                throw new Error(data.error || 'Request failed');
            }
            setMessage('Please check your email for reset instructions.');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="demo-container">
            {/* Left side: Forgot Password form */}
            <div className="left-side">
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                }}>
                    <h2>Forgot Password</h2>
                    {error && <p className="error">{error}</p>}
                    {message ? (
                        <p className="success">{message}</p>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <input
                                className="input-field"
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button type="submit" disabled={loading}>
                                {loading ? 'Submitting...' : 'Reset Password'}
                            </button>
                        </form>
                    )}
                    <div className="login-links">
                        <a href="/login">Login</a>
                        <span> | </span>
                        <a href="/signup">Sign Up</a>
                    </div>
                </div>
                <div className="footer-demo">
                    <div style={{ paddingTop: 10 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="120.125" height="30.965"
                             viewBox="0 0 208.125 56.965">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_1" data-name="Rectangle 1" width="208.125" height="56.965"
                                          fill="none"
                                          stroke="#ff9200" stroke-width="1"/>
                                </clipPath>
                                <clipPath id="clip-path-2">
                                    <rect id="Rectangle_2" data-name="Rectangle 2" width="208.125" height="56.965"
                                          fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Group_13" data-name="Group 13" transform="translate(-365.811 -22)">
                                <g id="Group_2" data-name="Group 2" transform="translate(365.811 22)">
                                    <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                                        <text id="V" transform="translate(0 31.465)" fill="#f7981d" stroke="#ff9200"
                                              stroke-width="1" font-size="36" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">V</tspan>
                                        </text>
                                        <text id="oice" transform="translate(22.047 31.465)" fill="#f7981d"
                                              stroke="#ff9200"
                                              stroke-width="1" font-size="37" font-family="Helvetica-Bold, Helvetica"
                                              font-weight="700">
                                            <tspan x="0" y="0">oice</tspan>
                                        </text>
                                        <text id="PRO" transform="translate(96.443 31.465)" fill="#b2b2b2"
                                              font-size="37"
                                              font-family="Helvetica-BoldOblique, Helvetica" font-weight="700"
                                              font-style="oblique">
                                            <tspan x="0" y="0">PRO</tspan>
                                        </text>
                                        <text id="T" transform="translate(181.715 8.75)" fill="#989897" font-size="7"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">T</tspan>
                                        </text>
                                        <text id="M" transform="translate(187.015 9.75)" fill="#989897" font-size="8"
                                              font-family="Helvetica">
                                            <tspan x="0" y="0">M</tspan>
                                        </text>
                                        <path id="Path_1" data-name="Path 1"
                                              d="M24.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(4.024 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(20.838 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                        <path id="Path_2" data-name="Path 2"
                                              d="M15.25,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(1.87 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(9.685 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                                <text id="communications" transform="translate(411.026 67.416)" fill="#e0e0e0"
                                      font-size="13"
                                      font-family="Helvetica" letter-spacing="0.229em">
                                    <tspan x="0" y="0">communications</tspan>
                                </text>
                                <g id="Group_4" data-name="Group 4" transform="translate(365.811 22)">
                                    <g id="Group_3" data-name="Group 3" clip-path="url(#clip-path-2)">
                                        <path id="Path_3" data-name="Path 3"
                                              d="M33.251,34.473a3.718,3.718,0,1,1-3.718-3.718,3.718,3.718,0,0,1,3.718,3.718"
                                              transform="translate(6.178 7.36)" fill="#e6e6e6"/>
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.718" cy="3.718" r="3.718"
                                                transform="translate(31.992 38.115)" fill="none" stroke="#ccc"
                                                stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div>VoicePro Communications, Inc. @ 2025</div>
                    <div>
                        <a href="/terms">Terms of Use</a> | <a href="/privacy">Privacy</a>
                    </div>
                </div>
            </div>
            {/* Right side: Carousel (hidden on mobile) */}
            {!isMobile && (
                <div className="carousel-container">
                    <Carousel/>
                </div>
            )}
        </div>
    );
}
